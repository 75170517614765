import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button, Request } from "../../Modules/Default";

import { ExperiaUtils } from "../../Modules/Experia";

const utils = ExperiaUtils();

export default function CalendarWidget() {
    const nav = useNavigate();

    const [ cook ] = useCookies();
    const [ calendar, setCalendar ] = useState( [] );

    const [ loading, setLoading ] = useState( true );

    useEffect( () => {
        Request( "getWorktimes.php", { token: cook.token } ).then( ( rsp ) => {
            if ( rsp?.calendar ) {
                setCalendar( rsp.calendar.slice(0, 3).map( ( day ) => ( {
                    ...day,
                    date: utils.date.format( day.date )
                } ) ) );
            }
            setLoading( false );
        } );
    }, [] );

    return (
        <div>
            <div className="border-b text-xl px-3 pb-3 border-gray-200 grid grid-cols-12">
                <div className="col-span-6 flex items-center font-semibold">
                    Ultimi Inserimenti
                </div>
                <div className="col-span-6 flex justify-end items-center">
                    <Button className="p-1" palette="primary" href="/calendar/form" >
                        <PlusIcon className="w-8 h-8 text-lg" />
                    </Button>
                </div>
            </div>
            <ul className="divide-y divide-gray-100 border-b border-gray-200 my-3">
                {
                    loading ? (
                        <div>
                            <li className="flex justify-between py-3 my-2 px-5 shadow-sm rounded-xl bg-white">
                                <div className="flex animate-pulse min-w-0 items-center">
                                    <div className="min-w-0 flex-auto">
                                        <div className="w-72 h-5 rounded-full bg-gray-300"></div>
                                    </div>
                                </div>
                                <div className="flex animate-pulse flex-col items-end justify-center text-end">
                                    <div className="w-20 h-5 rounded-full mb-1 bg-gray-300"></div>
                                </div>
                            </li>

                            <li className="flex justify-between py-3 my-2 px-5 shadow-sm rounded-xl bg-white">
                                <div className="flex animate-pulse min-w-0 items-center">
                                    <div className="min-w-0 flex-auto">
                                        <div className="w-32 h-5 rounded-full bg-gray-300"></div>
                                    </div>
                                </div>
                                <div className="flex animate-pulse flex-col items-end justify-center text-end">
                                    <div className="w-64 h-5 rounded-full mb-1 bg-gray-300"></div>
                                </div>
                            </li>

                            <li className="flex justify-between py-3 my-2 px-5 shadow-sm rounded-xl bg-white">
                                <div className="flex animate-pulse min-w-0 items-center">
                                    <div className="min-w-0 flex-auto">
                                        <div className="w-52 h-5 rounded-full bg-gray-300"></div>
                                    </div>
                                </div>
                                <div className="flex animate-pulse flex-col items-end justify-center text-end">
                                    <div className="w-32 h-5 rounded-full mb-1 bg-gray-300"></div>
                                </div>
                            </li>
                        </div>
                    ) : (
                        calendar && calendar.length > 0 ? (
                            calendar.map( ( day, dayIndex ) => (
                                <li key={dayIndex} className="flex justify-between py-3 my-2 px-5 shadow-sm rounded-xl bg-white">
                                    <div className="flex min-w-0 items-center">
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                                {day.date}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-end justify-center text-end">
                                        {
                                            day.worktimes.map( ( worktime, worktimeIndex ) => (
                                                <p key={worktimeIndex} className="text-sm leading-6 text-gray-900">
                                                    {worktime.amount} ore di {worktime.type} {worktime.projects.length > 0 && " su " + worktime.projects.map( ( project ) => project.NAME ).join( ", " )}
                                                </p>
                                            ) )
                                        }
                                    </div>
                                </li>
                            ) )
                        ) : (
                            <div className="flex justify-center text-center font-semibold text-lg">Non ci sono i dati...</div>
                        )
                    )
                }
            </ul>
            <div className="flex justify-center">
                <Button className="w-72 py-1" palette="more" href="/calendar" > Mostra Tutti </Button>
            </div>
        </div>
    );
}