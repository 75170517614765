import { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { useCookies } from "react-cookie";
import { Request } from "../Modules/Default";

const AuthContext = createContext({
    auth: false,
    login: () => {},
    logout: () => {}
});

export const useAuth = () => {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [cook, sCook, dCook] = useCookies(['token']);
    const [auth, sAuth] = useState(false);
    
    const login = async (username = "", password = "") => {
        if(username && password) {
            const rsp = await Request('login.php', {
                username: username,
                password: password
            });

            if(rsp && rsp.isValid) {
                const expire = new Date();
                expire.setDate(expire.getDate() + rsp.expire);
                sCook("token", rsp.token, { expires: expire });
                sAuth(true);
                return true;
            }
        } else {
            const rsp = await Request('login.php', { token: cook.token  });
            if(rsp && rsp.isValid) {
                sAuth(true);
                return true;
            } else {
                dCook('token');
                sAuth(false);
            }
        }

        return false;
    }

    const logout = async () => {
        const rsp = await Request('logout.php', { token: cook.token });
        if(rsp) {
            dCook('token');
            sAuth(false);
        }
    }

    useEffect(() => {
        if(cook.token) {
            login();
        }
    }, []);

    return (
        <AuthContext.Provider value={{ login: login, logout: logout, auth: auth }}>
            {children}
        </AuthContext.Provider>
    );

}