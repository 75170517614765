import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button, Confirm, Input, Loading, Request, Text } from "../../Modules/Default";
import { ClockIcon } from '@heroicons/react/20/solid'

import { ExperiaUtils } from "../../Modules/Experia";
import * as XLSX from "xlsx";


const utils = ExperiaUtils();

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Calendar() {
    const [ cook ] = useCookies([ "token" ]);
    const [ days, setDays ] = useState([]);
    const [ date, setDate ] = useState(new Date());
    const [ loading, setLoading ] = useState(true);
    const [ confirm, setConfirm ] = useState({ date: "", open: false });
    const [ selectedDay, setSelectedDay ] = useState(undefined);

    const currentMonth = date.toLocaleString('default', { month: 'long' });
    const currentMonthInText = currentMonth.substring(0, 1).toUpperCase() + currentMonth.substring(1) + " " + date.getFullYear();

    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "DataSheet.xlsx");
    };

    const loadCalendar = (month = date.toISOString().slice(0, 7)) => {
        setLoading(true);

        const from = utils.date.addDays(utils.date.dayOne(date), 1 - utils.date.dayOfWeek(utils.date.dayOne(date)));
        const to = utils.date.addDays(from, 41);

        Request("getWorktimes.php", { token: cook.token, from: utils.date.string.to(from), to: utils.date.string.to(to) }).then((rsp) => {
            if (rsp) {
                let items = [];

                for (let i = utils.date.number.to(from); i <= utils.date.number.to(to); i = utils.date.addDays(i, 1)) {
                    const _date = utils.date.string.to(i);
                    const isToday = _date === utils.date.string.to(new Date());
                    const isCurrentMonth = _date.slice(0, 7) === month;
                    const isSelected = _date === selectedDay?.date;

                    let events = [];
                    if (rsp.calendar) {
                        events = rsp.calendar.find((item) => item.date === _date)?.worktimes.map(worktime => ({
                            id: worktime.id,
                            name: worktime.type,
                            time: worktime.amount,
                            projects: worktime.projects.map(project => ({
                                id: project.PROJECT,
                                name: project.NAME,
                                amount: project.AMOUNT
                            }))
                        })) || [];
                    }
                    items.push({ date: _date, isToday: isToday, isCurrentMonth: isCurrentMonth, isSelected: isSelected, events: events });
                }
                setDays(items);
            }
            setLoading(false);
        });
    }

    const deleteHandler = (date) => {
        setConfirm({ ...confirm, open: false })
        setSelectedDay(undefined);
        Request("delWorktimes.php", { token: cook.token, date: date }).then((rsp) => {
            loadCalendar();
        });
    }

    useEffect(() => {
        loadCalendar();
    }, [ date ]);

    return (
        <div>
            <Confirm
                open={ confirm.open }
                sOpen={ (open) => setConfirm({ ...confirm, open: open }) }
                title="Conferma eliminazione"
                type="warn"
                text="Sei sicuro di voler eliminare questo giorno?"
                onConfirm={ () => { deleteHandler(confirm.date) } }
                onCancel={ () => { setConfirm({ open: false }) } }
                confirmButtonText="Elimina"
                cancelButtonText="Annulla"
            />

            <div className="lg:flex lg:h-full lg:flex-col">
                <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        <Text palette="h2">{ currentMonthInText }</Text>
                    </h1>
                    <div className="flex items-center">
                        <div className="hidden md:ml-4 md:flex md:items-center mx-4">
                            <Button
                                palette="secondary"
                                className="p-2"
                                onClick={ () => {
                                    let data = [];
                                    days.forEach(day => {
                                        if (day.isCurrentMonth) {
                                            data.push({
                                                "Data": day.date,
                                                ...day.events.reduce((obj, item) => {
                                                    obj[ item.name ] = item.time;
                                                    return obj;
                                                }, {
                                                    "Totale": day.events.reduce((tot, item) => tot + parseFloat(item.time), 0),
                                                    "Progetti": day.events.reduce((tot, item) => tot + item.projects.map(project => project.name).join(", "), "")
                                                })
                                            })
                                        }
                                    });
                                    downloadExcel(data);
                                } }

                            >
                                Esporta Excel
                            </Button>
                        </div>
                        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                            <Input
                                palette="default"
                                name="month"
                                type="month"
                                value={ date.toISOString().slice(0, 7) }
                                onInput={ (val) => {
                                    setDate(new Date(val));
                                } }
                            />
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                            <Button
                                palette="primary"
                                className="p-2"
                                href="/calendar/form"
                            >
                                Inserisci Ore
                            </Button>
                        </div>
                    </div>
                </header>

                {
                    loading ? (
                        <div className="mt-10">
                            <Loading show={ loading } />
                        </div>
                    ) : (
                        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                                <div className="bg-white py-2">
                                    L<span className="sr-only sm:not-sr-only">un</span>
                                </div>
                                <div className="bg-white py-2">
                                    M<span className="sr-only sm:not-sr-only">ar</span>
                                </div>
                                <div className="bg-white py-2">
                                    M<span className="sr-only sm:not-sr-only">er</span>
                                </div>
                                <div className="bg-white py-2">
                                    G<span className="sr-only sm:not-sr-only">io</span>
                                </div>
                                <div className="bg-white py-2">
                                    V<span className="sr-only sm:not-sr-only">en</span>
                                </div>
                                <div className="bg-white py-2">
                                    S<span className="sr-only sm:not-sr-only">ab</span>
                                </div>
                                <div className="bg-white py-2">
                                    D<span className="sr-only sm:not-sr-only">om</span>
                                </div>
                            </div>
                            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                                <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                                    { days.map((day) => (
                                        <div
                                            onClick={ () => { setSelectedDay(day) } }
                                            key={ day.date }
                                            className={ classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-100 text-gray-500',
                                                'duration-75 relative px-3 py-2 h-32 cursor-pointer hover:ring-indigo-300 hover:bg-indigo-50/100 hover:ring-1'
                                            ) }
                                        >
                                            <time
                                                dateTime={ day.date }
                                                className={
                                                    day.isToday
                                                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                                                        : ""
                                                }
                                            >
                                                { day.date.split('-').pop().replace(/^0/, '') }
                                            </time>
                                            { day.events.length > 0 && (
                                                <ol className="mt-2">
                                                    { day.events.slice(0, 2).map((event) => (
                                                        <li key={ event.id }>
                                                            <div className="group flex">
                                                                <p className="flex-auto truncate font-medium text-gray-900">
                                                                    { event.name }
                                                                </p>
                                                                <time
                                                                    dateTime={ event.datetime }
                                                                    className="ml-3 hidden flex-none text-gray-500 xl:block"
                                                                >
                                                                    { event.time }
                                                                </time>
                                                            </div>
                                                        </li>
                                                    )) }
                                                    { day.events.length > 2 && (
                                                        <li className="text-gray-500 cursor-pointer">
                                                            + { day.events.length - 2 }
                                                        </li>
                                                    ) }
                                                </ol>
                                            ) }
                                        </div>
                                    )) }
                                </div>
                                <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                                    { days.map((day) => (
                                        <button
                                            onClick={ () => { setSelectedDay(day) } }
                                            key={ day.date }
                                            type="button"
                                            className={ classNames(
                                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                                (day.isSelected || day.isToday) && 'font-semibold',
                                                day.isSelected && 'text-white',
                                                !day.isSelected && day.isToday && 'text-indigo-600',
                                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                                'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                                            ) }
                                        >
                                            <time
                                                dateTime={ day.date }
                                                className={ classNames(
                                                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                                    day.isSelected && day.isToday && 'bg-indigo-600',
                                                    day.isSelected && !day.isToday && 'bg-gray-900',
                                                    'ml-auto'
                                                ) }
                                            >
                                                { day.date.split('-').pop().replace(/^0/, '') }
                                            </time>
                                            <span className="sr-only">{ day.events.length } events</span>
                                            { day.events.length > 0 && (
                                                <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                    { day.events.map((event) => (
                                                        <span key={ event.id } className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                                    )) }
                                                </span>
                                            ) }
                                        </button>
                                    )) }
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !loading && (
                        selectedDay && (
                            selectedDay?.events.length > 0 ? (
                                <div
                                    id="event-details"
                                    className="px-4 py-10 sm:px-6 lg:fixed lg:w-full lg:h-full lg:top-0 lg:left-0 lg:flex lg:items-center lg:justify-center lg:backdrop-blur-sm lg:bg-slate-950/10"
                                    onClick={ (e) => {
                                        if (e.target.id === "event-details")
                                            setSelectedDay(undefined);
                                    } }
                                >
                                    <ol className="divide-y relative divide-gray-200 lg:w-96 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">

                                        <Button
                                            className="p-2 lg:flex hidden absolute right-2 top-2"
                                            palette="more"
                                            onClick={ () => { setSelectedDay(undefined) } }
                                        >
                                            <XMarkIcon className="w-4 h-4" />
                                        </Button>
                                        <li className="group flex justify-center p-4">
                                            <p className="font-bold text-gray-950">{ selectedDay.date.split('-').reverse().join('/') }</p>
                                        </li>
                                        {
                                            selectedDay.events.map((day) => (
                                                <li key={ day.id } className="group flex p-4 pr-6">
                                                    <div className="flex-auto">
                                                        <p className="font-semibold text-gray-900">{ day.name }</p>
                                                        {
                                                            day.projects?.length > 0 && (
                                                                <div className="pl-2 text-gray-400">
                                                                    {
                                                                        day.projects.map((project) => (
                                                                            <div key={ project.id } className="flex">
                                                                                { project.name } - { project.amount }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        <time dateTime={ day.datetime } className="mt-2 flex items-center text-gray-700">
                                                            <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            { day.time }
                                                        </time>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        <li className="group flex justify-end p-4">
                                            <Button
                                                className="p-2 mx-1"
                                                palette="more"
                                                href={ "/calendar/form/" + selectedDay.date }
                                            >
                                                Modifica
                                            </Button>
                                            <Button
                                                className="p-2 mx-1"
                                                palette="more"
                                                onClick={ () => { setConfirm({ ...confirm, open: true, date: selectedDay.date }) } }
                                            >
                                                Elimina
                                            </Button>
                                        </li>
                                    </ol>
                                </div>
                            ) : (
                                <div
                                    id="event-details"
                                    className="px-4 py-10 sm:px-6 lg:fixed lg:w-full lg:h-full lg:top-0 lg:left-0 lg:flex lg:items-center lg:justify-center lg:backdrop-blur-sm lg:bg-slate-950/10"
                                    onClick={ (e) => {
                                        if (e.target.id === "event-details")
                                            setSelectedDay(undefined);
                                    } }
                                >
                                    <ol className="divide-y relative divide-gray-100 lg:w-96 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                                        <Button
                                            className="p-2 lg:flex hidden absolute right-2 top-2"
                                            palette="more"
                                            onClick={ () => { setSelectedDay(undefined) } }
                                        >
                                            <XMarkIcon className="w-4 h-4" />
                                        </Button>
                                        <li className="group flex p-4 pr-6 pt-8">
                                            <div className="flex-auto flex justify-center">
                                                <p className="font-semibold text-base text-gray-900">
                                                    Nessuna ora inserita
                                                </p>
                                            </div>
                                        </li>
                                        <li className="group flex p-4 pr-6">
                                            <div className="flex-auto flex justify-center">
                                                <p className="font-semibold text-gray-900">
                                                    <Button
                                                        palette="more"
                                                        className="px-3 py-2"
                                                        href={ "/calendar/form/" + selectedDay.date }
                                                    >Inserisci Ore</Button>
                                                </p>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            )
                        )
                    )
                }
            </div>
        </div>
    )
}