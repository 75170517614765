import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Fieldset, Input, Select, Text } from "../../Modules/Default";

import { ExperiaUtils } from "../../Modules/Experia";

const utils = ExperiaUtils();

export default function Projects( props ) {

    return (
        <div>
            <div className="flex items-end my-2 pb-2 border-b border-gray-300">
                <div className="w-full">
                    <Text palette="h4">Progetti</Text>
                </div>

                <div>
                    <Button
                        palette="primary"
                        className="px-3 py-2"
                        onClick={ () => {
                            const filtered = props.list.projects.filter( prj => ( !props.selected.includes( prj.value ) ) );
                            if ( filtered.length > 0 ) {
                                let amountDefault = props.free > 0 ? props.free : 0;
                                props.setProjects( [ ...props.projects, { amount: amountDefault, project: filtered[ 0 ].value } ] );
                            }
                        } }
                    >
                        <span className="pr-1">Progetto</span>
                        <PlusIcon className="w-5 h-5" />
                    </Button>
                </div>
            </div>

            {
                props.projects?.length > 0 ? (
                    <>
                        {
                            props.projects.map( ( item, key ) => (
                                <div key={ key } className="flex items-center py-2">
                                    <div className="w-full">
                                        <Fieldset>
                                            <div className="flex" d="flex" y="center" x="center">
                                                <Input
                                                    palette="fieldset-element"
                                                    className={ { input: "rounded-bl-md rounded-tl-md", group: "w-32" } }
                                                    placeholder="Ore"
                                                    name="hours"
                                                    type="number"
                                                    min={ 0.5 }
                                                    max={ props.max }
                                                    step={ 0.5 }
                                                    required
                                                    value={ item.amount }
                                                    onInput={ ( val ) => {
                                                        props.setProjects( utils.inject( props.projects, key, { amount: val } ) );
                                                    } }
                                                />
                                                <Select
                                                    palette="fieldset-element"
                                                    className={ { input: "rounded-br-md rounded-tr-md", group: "w-full" } }
                                                    name="project"
                                                    onChange={ ( val ) => props.setProjects( utils.inject( props.projects, key, { project: val } ) ) }
                                                    required={ true }
                                                    value={ item.project }
                                                    items={ props.list.projects.filter( prj => ( !props.selected.includes( prj.value ) || prj.value === item.project ) ) }
                                                />
                                            </div>
                                        </Fieldset>
                                    </div>

                                    <div className="flex justify-end pl-2">
                                        <Button
                                            palette="danger"
                                            className="w-8 h-8"
                                            onClick={ () => props.setProjects( utils.sieve( props.projects, key ) ) }
                                        >
                                            <TrashIcon className="w-6 h-6" />
                                        </Button>
                                    </div>
                                </div>
                            ) )
                        }
                        {
                            props.free !== 0 && (
                                <div className="flex items-center justify-center">
                                    <Text palette="h5" className="text-red-700">La somma delle ore dei progetti non coincidono con le ore di lavoro</Text>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <div className="flex items-center justify-center">
                        <Text palette="h5" className="text-red-700">Aggiungi almeno un progetto</Text>
                    </div>
                )
            }
        </div>
    )
} 