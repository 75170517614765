
export function ExperiaUtils() {
    return {
        sieve(obj, key) {
            return obj.filter( ( item, index ) => index !== key);
        },
        inject(obj, key, value) {
            return obj.map( ( item, index ) => ( index === key ? { ...item, ...value } : item ) );
        },
        date: {
            string: {
                from: ( strDate ) => new Date(strDate),
                to: ( date ) => {
                    if( typeof date === 'number') {
                        return date.toString().slice(0, 4) + '-' + date.toString().slice(4, 6) + '-' + date.toString().slice(6, 8);
                    } else if(typeof date === 'object') {
                        return date.toISOString().slice(0, 10);
                    }
                }
            },
            number: {
                from: ( numDate ) => new Date( numDate.toString().slice(0, 4) + '-' + numDate.toString().slice(4, 6) + '-' + numDate.toString().slice(6, 8) ),
                to: ( date ) => {
                    if( typeof date === 'string') {
                        return parseInt(date.split('-').join(''))
                    } else if(typeof date === 'object') {
                        return parseInt(date.toISOString().slice(0, 10).split('-').join(''))
                    }
                }
            },
            addDays: function( date, days ) {
                if( typeof date === 'number' ) {
                    const _date = this.number.from(date);
                    _date.setDate(_date.getDate() + days);
                    _date.setHours(12, 0, 0, 0);
                    return this.number.to(_date);
                } else if( typeof date === 'string' ) {
                    const _date = this.string.from(date);
                    _date.setDate(_date.getDate() + days);
                    _date.setHours(12, 0, 0, 0);
                    return this.string.to(_date);
                } else if( typeof date === 'object' ) {
                    const _date = this.string.from(date);
                    _date.setDate(date.getDate() + days);
                    _date.setHours(12, 0, 0, 0);
                    return _date;
                } else {
                    console.error('[EXPERIA UTILS] - addDays: Invalid date type');
                    return date;
                }
            },
            addMonths: function( date, months ) {
                if( typeof date === 'number' ) {
                    const _date = this.number.from(date);
                    _date.setMonth(_date.getMonth() + months);
                    return this.number.to(_date);
                } else if( typeof date === 'string' ) {
                    const _date = this.string.from(date);
                    _date.setMonth(_date.getMonth() + months);
                    return this.string.to(_date);
                } else if( typeof date === 'object' ) {
                    const _date = this.string.from(date);
                    _date.setMonth(_date.getMonth() + months);
                    return _date;
                } else {
                    console.error('[EXPERIA UTILS] - addDays: Invalid date type');
                    return date;
                }
            },
            addYears: function( date, years ) {
                if( typeof date === 'number' ) {
                    const _date = this.number.from(date);
                    _date.setFullYear(_date.getFullYear() + years);
                    return this.number.to(_date);
                } else if( typeof date === 'string' ) {
                    const _date = this.string.from(date);
                    _date.setFullYear(_date.getFullYear() + years);
                    return this.string.to(_date);
                } else if( typeof date === 'object' ) {
                    const _date = this.string.from(date);
                    _date.setFullYear(_date.getFullYear() + years);
                    return _date;
                } else {
                    console.error('[EXPERIA UTILS] - addDays: Invalid date type');
                    return date;
                }
            },
            dayOne: ( date = new Date() ) => {
                date.setDate(1);
                return date;
            },
            dayLast: ( date = new Date() ) => {
                date.setMonth(date.getMonth() + 1);
                date.setDate(0);
                return date;
            },
            dayOfWeek: ( date = new Date() ) => {
                return date.getDay() === 0 ? 7 : date.getDay();
            },
            format: function( date = new Date() ) {
                if( typeof date === 'string' ) {
                    return date.split('-').reverse().join('/');
                } else if( typeof date === 'number' ||  typeof date === 'object' ) {
                    return this.string.to(date).split('-').reverse().join('/');
                } else {
                    console.error('[EXPERIA UTILS] - addDays: Invalid date type');
                    return date;
                }
            },
        }
    }
}