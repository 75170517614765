import { useState } from "react";

import { ExperiaUtils } from "./Modules/Experia";

const utils = ExperiaUtils();

export default function Test( props ) {
    const [ data, setData ] = useState( { date: '', worktimes: [] } );

    return (
        <form
            onSubmit={( e ) => {
                e.preventDefault()
                console.log( data );
            }}
        >
            <input
                type="date"
                value={data.date}
                onChange={( e ) => setData( { ...data, date: e.target.value } )}
            />

            <Worktimes
                setWorktimes={( worktimes ) => { setData( { ...data, worktimes } ) }}
                worktimes={data.worktimes}
            />

            <button>Submit</button>
        </form>
    )
}

function Worktimes( props ) {
    return (
        <>
            <button
                className="bg-gray-400 px-3 py-1 mx-2"
                type="button"
                onClick={() => { props.setWorktimes( [ ...props.worktimes, { amount: "0", name: "", projects: [] } ] ) }}
            >
                Add
            </button>
            {
                props.worktimes.map( ( worktime, key ) => (
                    <div key={key}>
                        <button
                            type="button"
                            className="bg-gray-400 px-3 py-1 mx-2"
                            onClick={() => props.setWorktimes( utils.sieve( props.worktimes, key ) )}
                        >
                            Remove
                        </button>
                        <input
                            type="number"
                            value={worktime.amount}
                            onInput={( e ) => props.setWorktimes( utils.inject( props.worktimes, key, { amount: e.target.value } ) )}
                        />
                        <input
                            type="text"
                            value={worktime.name}
                            onInput={( e ) => props.setWorktimes( utils.inject( props.worktimes, key, { name: e.target.value } ) )}
                        />

                        <Projects
                            projects={worktime.projects}
                            setProjects={( projects ) => props.setWorktimes( utils.inject( props.worktimes, key, { projects: projects } ) )}
                        />
                    </div>
                ) )
            }
        </>
    )
}

function Projects( props ) {
    return (
        <div>
            <button
                type="button"
                className="bg-gray-400 px-3 py-1 mx-2"
                onClick={() => props.setProjects( [ ...props.projects, { name: "project" } ] )}
            >
                Add
            </button>

            {
                props.projects.map( ( project, key ) => (
                    <div key={key}>
                        <button
                            type="button"
                            className="bg-gray-400 px-3 py-1 mx-2"
                            onClick={() => props.setProjects( utils.sieve( props.projects, key ) )}
                        >
                            Remove
                        </button>
                        <input
                            type="text"
                            value={project.name}
                            onInput={( e ) => props.setProjects( utils.inject( props.projects, key, { name: e.target.value } ) )}
                        />
                    </div>
                ) )
            }
        </div>
    )
}
