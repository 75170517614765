import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, Button, Confirm, Input, Loading, Request, Text } from "../../Modules/Default";
import Worktimes from "./Worktimes";

import { ExperiaUtils } from "../../Modules/Experia";

const utils = new ExperiaUtils();

export default function CalendarForm() {
    const [cook] = useCookies("token");
    const params = useParams();
    const nav = useNavigate();

    const [alert, setAlert] = useState({ open: false, type: "warn", text: "", onClick: () => { setAlert({ ...alert, open: false }) } }); 
    const [confirm, setConfirm] = useState({ open: false });

    const [data, setData] = useState({ date: params.date ?? utils.date.string.to(new Date()), worktimes: [] });

    const [projects, setProjects] = useState([]);
    const [types, setTypes] = useState([]);
    const [projectReqs, setProjectReqs] = useState([]);
    const [list, setList] = useState({ types: types, projects: projects, projectReq: projectReqs });

    const [headLoading, setHeadLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);

    const fetchData = () => {
        setDataLoading(true);
        setData({
            ...data,
            worktimes: []
        });
        Request("getWorktimesByData.php", { token: cook.token, date: data.date }).then(rsp => {
            if (rsp && rsp.data) {
                setData({
                    ...data,
                    worktimes: rsp.data.worktimes.map((worktime) => ({
                        type: worktime.type,
                        amount: worktime.amount,
                        projects: worktime.projects.map((project) => ({
                            project: project.PROJECT,
                            amount: project.AMOUNT
                        }))
                    }))
                });
            }
            
            setDataLoading(false);
        });
    }

    useEffect(() => {
        setHeadLoading(true);
        Request("getProjects.php", { token: cook.token }).then(rsp => {
            setProjects(rsp);
        }).then(() => {
            Request("getWorktimeTypes.php", { token: cook.token }).then(rsp => {
                setTypes(rsp);
            }).then(() => {
                Request("getProjectReqs.php", { token: cook.token }).then(rsp => {
                    setProjectReqs(rsp);
                    setHeadLoading(false);
                });
            });
        });
    }, [])

    useEffect(() => {
        setList({ types: types, projects: projects, projectReqs: projectReqs });
    }, [projects, types, projectReqs])

    useEffect(() => {
        fetchData();
    }, [data.date])

    const submit = (e) => {
        e.preventDefault();
        console.log(data);
        if (Object.keys(data.worktimes).length === 0) {
            setAlert({
                ...alert,
                open: true,
                text: "Inserire almeno un'ora di lavoro!",
                onClick: () => { setAlert({ ...alert, open: false }); }
            })
        } else {
            setConfirm({
                ...confirm,
                open: true
            });
        }
    }

    const confirmInsertHandler = () => {
        setConfirm({ ...confirm, open: false });

        Request("setWorktimes.php", { token: cook.token, data: data }).then(rsp => {
            if (rsp) {
                if (rsp.isValid) {
                    setAlert({
                        ...alert,
                        open: true,
                        type: "success",
                        text: "Ore inserite correttamente!",
                        onClick: () => { nav("/calendar"); }
                    });
                } else {
                    setAlert({
                        ...alert,
                        open: true,
                        type: "warn",
                        text:   rsp.code === 'INVALID_AMOUNT' ?
                                    "Le ore di lavoro e le ore dei progetti non coincidono!" :
                                rsp.code === 'INVALID_PROJECT' ?
                                    "Inserisci almeno un progetto per le ore di lavoro!" :
                                    "Errore sconosciuto!",
                        onClick: () => { setAlert({ ...alert, open: false }); }
                    });
                }
            }
        });
    }

    return (
        <div>
            <Alert
                open={alert.open}
                type={alert.type}
                sOpen={ (val) => setAlert({ ...alert, open: val }) }
                title="Attenzione!"
                text={alert.text}
                buttonText="Conferma"
                onClick={alert.onClick}
            />

            <Confirm
                onConfirm={confirmInsertHandler}
                open={confirm.open}
                sOpen={ (val) => setConfirm({ ...confirm, open: val }) }
                text="Confermi l'inserimento delle ore?"
                type="info"
                title="Conferma"
                confirmButtonText="Conferma"
                cancelButtonText="Annulla"
                onCancel={ () => setConfirm({ ...confirm, open: false }) }
            />
            <div className="border-b border-gray-200 px-6 py-4 mb-4">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                    <Text palette="h2">Inserimento Ore</Text>
                </h1>
            </div>


            <Loading show={headLoading}>
                <form className="w-full box-border p-0" onSubmit={submit} >   
                    <div>
                        <div className="pb-2">
                            <div className="flex items-center">
                                <div className="flex w-full">
                                    <Input
                                        palette="inset"
                                        className={{ group: "sm:w-60 w-full" }}
                                        label="Data"
                                        name="date"
                                        id="date"
                                        type="date"
                                        required={true}
                                        value={data.date}
                                        onInput={(val) => setData({ ...data.date, date: val }) }
                                    />
                                </div>
    
                            </div>
                        </div>

                        <Worktimes
                            setWorktimes={( worktimes ) => { setData( { ...data, worktimes } ) }}
                            worktimes={data.worktimes}
                            list={list}
                            dataLoading={dataLoading}
                        />
                    </div>
                    <div className="h-12"></div>
                    <div className="w-full flex justify-center bottom-4 left-0 fixed px-4">
                        <div className="flex items-center justify-end py-2 px-4  max-w-7xl w-full box-border bg-white shadow-sm rounded-md border gap-x-3">
                            <Button palette="danger-text" href="/calendar" className="px-5 py-2">
                                Indietro
                            </Button>
                            <Button type="submit" palette="primary" className="px-5 py-2">
                                Salva
                            </Button>
                        </div>
                    </div>
                </form>
            </Loading>
        </div>
    );
}