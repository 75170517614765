import { useState } from "react";
import { useAuth } from "../Context/Auth";
import Logo from "../Resources/Logo.png";
import { Button, Alert, Input } from "../Modules/Default";

function Login() {
    const [ username, sUsername ] = useState( '' );
    const [ password, sPassword ] = useState( '' );
    const { login } = useAuth();

    const [ openAlert, sOpenAlert ] = useState( false );

    const submitHandler = async ( e ) => {

        e.preventDefault();
        const isValid = await login( username, password );

        if ( !isValid ) {
            sOpenAlert( true );
        }

    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <Alert
                open={openAlert}
                sOpen={sOpenAlert}
                type="warn"
                title="Attenzione!"
                text="Combinazione di nome utente e password non valida!"
                buttonText="Continua"
                onClick={() => sOpenAlert( false )}
            />
            <div className="sm:mx-auto sm:w-full sm:max-w-sm pb-12">
                <img
                    className="mx-auto h-36 w-auto"
                    src={Logo}
                    alt="Wdia"
                />
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-sm text-2xl mt-8 mb-4 flex font-semibold justify-center ">
                Gestione Ore
            </div>

            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-4" onSubmit={submitHandler}>
                    <Input
                        palette="default"
                        name="username"
                        type="text"
                        label="Username"
                        autoComplete="username"
                        required
                        value={username}
                        onInput={( val ) => sUsername( val.toLowerCase().replace(' ', '') )}
                    />

                    <Input
                        palette="default"
                        name="password"
                        type="password"
                        label="Password"
                        autoComplete="current-password"
                        required
                        onInput={( val ) => sPassword( val )}
                    />

                    
                    <div className="flex justify-end">
                        <a href="#" className="text-sm font-semibold text-green-600 hover:text-green-500">
                            Hai dimenticato la password?
                        </a>
                    </div>

                    <div>
                        <Button className="w-full py-2" palette="secondary" type="submit">
                            Sign in
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;