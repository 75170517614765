import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Input, Loading, Select, Text } from "../../Modules/Default";
import { ExperiaUtils } from "../../Modules/Experia";
import Projects from "./Projects";

const utils = ExperiaUtils();

export default function Worktimes( props ) {
    return (
        <div>
            <div className="flex items-end my-2 pb-2 border-b border-gray-300">
                <div className="w-full">
                    <Text palette="h4">Ore</Text>
                </div>

                <div>
                    <Button
                        palette="primary"
                        className="px-3 py-2 whitespace-nowrap"
                        onClick={ () => { props.setWorktimes( [ ...props.worktimes, { amount: "8", type: "1", projects: [] } ] ) } }
                    >
                        <span className="pr-1">Ora</span>
                        <PlusIcon className="w-5 h-5" />
                    </Button>
                </div>
            </div>

            <Loading show={ props.dataLoading }>
                {
                    props.worktimes?.length > 0 ? (
                        props.worktimes.map( ( item, key ) => (
                            <div key={ key } className="mb-3 bg-white rounded-xl shadow-sm p-3">
                                <div>
                                    <div className="flex items-center">
                                        <div className="flex justify-center pr-2">
                                            <Button
                                                palette="danger"
                                                className="w-8 h-8"
                                                onClick={ () => props.setWorktimes( utils.sieve( props.worktimes, key ) ) }
                                            >
                                                <TrashIcon className="w-6 h-6" />
                                            </Button>
                                        </div>

                                        <div className="w-full">
                                            <fieldset>
                                                <div className="flex rounded-md bg-white shadow-sm">
                                                    <Select
                                                        palette="fieldset-element"
                                                        className={ { input: "rounded-bl-md rounded-tl-md", group: "w-full" } }
                                                        name="type"
                                                        onChange={ ( val ) => {
                                                            if ( !props.list.projectReqs?.includes( val ) ) {
                                                                props.setWorktimes( utils.inject( props.worktimes, key, { type: val, projects: [] } ) );
                                                            } else {
                                                                props.setWorktimes( utils.inject( props.worktimes, key, { type: val } ) );
                                                            }
                                                        } }
                                                        required={ true }
                                                        value={ item.type }
                                                        items={ props.list.types }
                                                    />
                                                    <Input
                                                        palette="fieldset-element"
                                                        className={ { input: "rounded-br-md rounded-tr-md", group: "w-32" } }
                                                        type="number"
                                                        name="hours"
                                                        min={ 0.5 }
                                                        max={ 24 }
                                                        step={ 0.5 }
                                                        required={ true }
                                                        value={ item.amount }
                                                        placeholder="Ore"
                                                        onInput={ ( val ) => props.setWorktimes( utils.inject( props.worktimes, key, { amount: val } ) ) }
                                                    />
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    {
                                        props.list.projectReqs?.includes( item.type ) && (
                                            <Projects
                                                selected={ item.projects.map( item => item.project ) }
                                                projects={ item.projects }
                                                free={ parseFloat( item.amount ) - item.projects.reduce( ( res, num ) => res + ( parseFloat( num.amount ) ?? 0 ), 0 ) }
                                                max={ parseFloat( item.amount ) }
                                                setProjects={ ( projects ) => props.setWorktimes( utils.inject( props.worktimes, key, { projects: projects } ) ) }
                                                list={ props.list }
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        ) )
                    ) : (
                        <div className="flex items-center justify-center h-16">
                            <Text palette="h5">Nessuna ora inserita</Text>
                        </div>
                    )
                }
            </Loading>
        </div>
    )
}