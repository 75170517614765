import CalendarWidget from "./Widgets/CalendarWidget";

function Dashboard() {
    return (
        <div className="w-full box-border">
            <div className="space-y-12">
                <CalendarWidget />
            </div>
        </div>
    );
}

export default Dashboard;