import { Navigate, Route, Routes } from 'react-router';
import { useAuth } from './Context/Auth';
import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import DefaultUserImage from "./Resources/DefaultUserImage.png";
import Logo from "./Resources/Logo.png";
import { useNavigate } from "react-router";
import { useCookies } from "react-cookie";

import Dashboard from './Pages/Dashboard';
import Signin from './Pages/Signin';
import Calendar from './Pages/Calendar/Calendar';
import Settings from './Pages/Settings';
import CalendarForm from './Pages/Calendar/CalendarForm';
import config from './config.json';
import { Alert, Confirm, Request } from './Modules/Default';
import Test from './Test';

function classNames( ...classes ) {
    return classes.filter( Boolean ).join( ' ' )
}

function App() {
    const { auth } = useAuth();

    useEffect( () => {
        if ( auth ) {
            document.getElementById( 'theme-color' ).setAttribute( 'content', '#1f2937' );
        } else {
            document.getElementById( 'theme-color' ).setAttribute( 'content', '#ffffff' );
        }
    }, [ auth ] );

    return (
        <>
            {
                auth ? (
                    <Navbar>
                        <Routes>
                            <Route path="/" element={<Dashboard />}></Route>
                            <Route path="/calendar" element={<Calendar />}></Route>
                            <Route path="/settings" element={<Settings />}></Route>
                            <Route path="/calendar/form" element={<CalendarForm />}></Route>
                            <Route path="/calendar/form/:date" element={<CalendarForm />}></Route>
                            <Route path="/test" element={<Test />}></Route>
                            <Route path="*" element={<Navigate to="/" />}></Route>
                        </Routes>
                    </Navbar>
                ) : (
                    <Routes>
                        <Route path="/" element={<Signin />}></Route>
                        <Route path="*" element={<Navigate to="/" />}></Route>
                    </Routes>
                )
            }
        </>
    );
}

function Navbar( { children } ) {

    const { logout } = useAuth();

    const nav = useNavigate();

    const [ cook ] = useCookies( [ 'token' ] );

    const [ openConfirm, setOpenConfirm ] = useState( false );
    const [ openSoftwareInfo, setOpenSoftwareInfo ] = useState( false );
    const [ user, setUser ] = useState( {
        name: '',
        email: '',
        image: DefaultUserImage,
        role: '',
        alt: 'U'
    } );

    const navigation = [
        { name: 'Dashboard', onClick: () => { nav( '/' ) }, path: '/' },
        { name: 'Calendario', onClick: () => { nav( '/calendar' ) }, path: '/calendar' }
    ]

    const userNavigation = [
        { name: 'Impostazioni', onClick: () => nav( '/settings' ) },
        { name: 'Info', onClick: () => setOpenSoftwareInfo(true) },
        { name: 'Esci', onClick: () => setOpenConfirm( true ) },
    ]

    useEffect( () => {
        if ( cook.token ) {
            Request( 'getUserData.php', { token: cook.token } ).then( rsp => {
                if ( rsp ) {
                    setUser( {
                        name: rsp.NAME + ' ' + rsp.SURNAME,
                        email: rsp.EMAIL,
                        image: undefined,
                        alt: rsp.NAME[ 0 ] + rsp.SURNAME[ 0 ],
                        role: rsp.ROLE
                    } );
                }
            } );
        }
    }, [] )

    const logouHandler = () => logout().then( () => nav( '/' ) );

    return (
        <div className="h-full">
            <Confirm
                text="Sei sicuro di voler uscire?"
                title="Attenzione!"
                type="info"
                open={openConfirm}
                sOpen={setOpenConfirm}
                onConfirm={logouHandler}
                onCancel={() => setOpenConfirm( false )}
                confirmButtonText="Esci"
                cancelButtonText="Annulla"
            />

            <Alert
                title="Software Info"
                text={
                    <>
                        <div>WDia developed by System Service srl</div>
                        <div>Current Version: {config.version}</div>
                    </>
                }
                type="info"
                open={openSoftwareInfo}
                sOpen={setOpenSoftwareInfo}
                onClick={() => setOpenSoftwareInfo( false )}
                buttonText="Ok"
            />

            <Disclosure as="nav" className="fixed z-50 w-full bg-gray-800" >
                {( { open } ) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 box-border">
                            <div className="flex h-12 items-center justify-between">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-8 w-8 cursor-pointer"
                                            src={Logo}
                                            alt="WDia"
                                            onClick={() => { nav( '/' ) }}
                                        />
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-10 flex items-baseline space-x-4">
                                            {navigation.map( ( item ) => (
                                                <button
                                                    key={item.name}
                                                    onClick={item.onClick}
                                                    className={
                                                        classNames(
                                                            CurrentPath( item.path ) ? (
                                                                'bg-gray-900 text-white'
                                                            ) : (
                                                                'text-gray-300 hover:bg-gray-700 hover:text-white'
                                                            ),
                                                            'rounded-md px-3 py-2 text-sm font-medium'
                                                        )
                                                    }
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </button>
                                            ) )}
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-4 flex items-center md:ml-6">

                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">Open user menu</span>
                                                    {
                                                        user.image ? (
                                                            <img className="h-8 w-8 rounded-full" src={user.image} alt="U" />
                                                        ) : (
                                                            <div className="h-8 w-8 rounded-full bg-gray-500 text-white flex items-center justify-center">
                                                                {user.alt}
                                                            </div>
                                                        )
                                                    }
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {userNavigation.map( ( item ) => (
                                                        <Menu.Item key={item.name}>
                                                            {( { active } ) => (
                                                                <button
                                                                    onClick={item.onClick}
                                                                    className={classNames(
                                                                        (
                                                                            active ?
                                                                                'bg-gray-100' :
                                                                                ''
                                                                        ),
                                                                        'w-full block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    ) )}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                                <div className="-mr-2 flex md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {
                                            open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )
                                        }
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">

                                {
                                    navigation.map( ( item ) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="button"
                                            onClick={item.onClick}
                                            className={classNames(
                                                CurrentPath( item.path ) ? (
                                                    'bg-gray-900 text-white'
                                                ) : (
                                                    'text-gray-300 hover:bg-gray-700 hover:text-white'
                                                ),
                                                'block rounded-md px-3 py-2 text-base font-medium w-full text-left'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ) )
                                }

                            </div>

                            <div className="border-t border-gray-700 pb-3 pt-4">
                                <div className="flex items-center px-5">
                                    <div className="flex-shrink-0">
                                        {
                                            user.image ? (
                                                <img className="h-10 w-10 rounded-full" src={user.image} alt={user.alt} />
                                            ) : (
                                                <div className="h-10 w-10 rounded-full bg-gray-500 text-white flex items-center justify-center">
                                                    {user.alt}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium leading-none text-white">{user.name}</div>
                                        <div className="text-sm leading-none text-gray-400">{user.role}</div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1 px-2">
                                    {userNavigation.map( ( item ) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            onClick={item.onClick}
                                            className="block  w-full text-left rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ) )}
                                </div>
                            </div>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <div style={{ height: "48px" }}></div>
            <main className="overflow-y-auto" style={{ height: "calc(100% - 48px)" }}>
                <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 box-border">{children}</div>
            </main>
        </div>
    );
}

function CurrentPath( path ) {
    return window.location.pathname === path ||
        window.location.pathname.substring( 0, window.location.pathname.indexOf( '/', 1 ) ) === path
}

export default App;
