import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Request } from "../Modules/Default";
import { useNavigate } from "react-router";


function Settings() {
    const [cook] = useCookies(["token"]);

    const [password, sPassword] = useState("");
    const [confirmPassword, sConfirmPassword] = useState("");

    const nav = useNavigate();

    useEffect(()=> {
        Request('getUserData.php', {
            token: cook.token
        }).then(rsp => {
            document.getElementById("first-name").value = rsp.NAME;
            document.getElementById("last-name").value = rsp.SURNAME;
            document.getElementById("email").value = rsp.EMAIL;
        });
    }, []);

    useEffect(()=> {
        if(password !== "" && confirmPassword !== "" && password === confirmPassword) {
            document.getElementById("submit").disabled = false;
        } else {
            document.getElementById("submit").disabled = true;
        }

        if(password !== "" || confirmPassword !== "") {
            document.getElementById("cancel").disabled = false;
        } else {
            document.getElementById("cancel").disabled = true;
        }
    }, [password, confirmPassword]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(password === confirmPassword) {
            Request("setPassword.php", {
                token: cook.token,
                password: password
            }).then(rsp => {
                window.alert('Password cambiata con successo');
                nav("/");
            });
        } else {
            window.alert('Le password non coincidono');
        }
    }

    const cancelHandler = (e) => {
        console.log("cancel");
        document.getElementById("password").value = "";
        document.getElementById("confirm-password").value = "";
        sPassword("");
        sConfirmPassword("");
    }

    return (
        <form onSubmit={handleSubmit} className="w-full box-border">
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Account</h2>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Nome
                            </label>
                            <div className="mt-2">
                                <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                required
                                placeholder="John"
                                autoComplete="given-name"
                                disabled
                                className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Cognome
                            </label>
                            <div className="mt-2">
                                <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                required
                                placeholder="Doe"
                                autoComplete="family-name"
                                disabled
                                className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email
                            </label>
                            <div className="mt-2">
                                <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                placeholder="email@example.com"
                                disabled
                                className="disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Nuova Password
                            </label>
                            <div className="mt-2">
                                <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="password"
                                onInput={(e) => sPassword(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        
                        <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Conferma Password
                            </label>
                            <div className="mt-2">
                                <input
                                    id="confirm-password"
                                    name="confirm-password"
                                    type="password"
                                    onInput={(e) => sConfirmPassword(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                    </div>
                </div>

                {/*<div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Notifiche</h2>

                    <div className="mt-10 space-y-10">
                        <fieldset>
                            <div className="mt-6 space-y-6">

                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="comments" className="font-medium text-gray-900">Nuovo dispositivo</label>
                                        <p className="text-gray-500">Notifica quando viene effettuato login con questo account.</p>
                                    </div>
                                </div>

                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input  
                                            id="candidates"
                                            name="candidates"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="candidates" className="font-medium text-gray-900">Ore inoltrate</label>
                                        <p className="text-gray-500">Notifica se le ore del mese sono state inoltrate</p>
                                    </div>
                                </div>

                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="offers"
                                            name="offers"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="offers" className="font-medium text-gray-900">Promemoria</label>
                                        <p className="text-gray-500">Notifica promemoria per inserimento ore del giorno corrente.</p>
                                    </div>
                                </div>

                            </div>
                        </fieldset>
                    </div>
                </div> */}
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    id="cancel"
                    type="button"
                    onClick={cancelHandler}
                    className="disabled:text-gray-300 text-sm font-semibold leading-6 text-red-500"
                >
                    Cancel
                </button>
                <button
                    id="submit"
                    type="submit"
                    className="disabled:bg-gray-300 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Salva
                </button>
            </div>
        </form>
    );
}

export default Settings;