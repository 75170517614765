import { useNavigate } from "react-router";
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'

import config from '../config.json';


const styles = {
    "button": {
        "@default": "flex justify-center items-center disabled:pointer-events-none rounded-md text-sm font-semibold disabled:bg-gray-400 disabled:text-gray-500",
        "primary": "shadow-sm text-white bg-indigo-600 hover:bg-indigo-500",
        "primary-text": "text-indigo-600 hover:text-indigo-500",
        "secondary": "shadow-sm text-white bg-green-600 hover:bg-green-500",
        "more": "shadow-sm text-black border bg-white hover:bg-gray-50",
        "danger": "text-white bg-red-600 hover:bg-red-500",
        "danger-text": "text-red-500"
    },
    "text" : {
        "@default": "",
        "h1": "text-2xl font-bold",
        "h2": "text-xl font-semibold",
        "h3": "text-lg font-semibold",
        "h4": "text-base font-semibold",
        "h5": "text-sm"
    },
    "input": {
        "@default": {
                "group": "",
                "label": "",
                "input": ""
        },
        "default": {
                "group": "",
                "label": "block text-sm font-medium leading-6 text-gray-900",
                "input": "block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        },
        "inset": {
                "group": "rounded-md px-3 pb-1 pt-2 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 bg-white",
                "label": "block text-xs font-medium text-gray-900",
                "input": "block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
        },
        "fieldset-element": {
                "group": "",
                "label": "",
                "input": "relative block w-full rounded-none border-0 bg-transparent py-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        }
    }
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export function Button( props ) {
    const nav = useNavigate();

    return (
        <button
            className={classNames(
                ( styles.button[ "@default" ] ),
                ( styles.button[ props.palette ?? "" ] ?? "" ),
                ( props.className ?? "" )
            )}
            type={props.type ?? "button"}
            disabled={props.disabled}
            onClick={props.onClick ?? ( props.href && ( () => nav( props.href ) ) )}
        >
            {props.children}
        </button >
    );
}

export function Confirm( props ) {

    const cancelButtonRef = useRef( null )

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.sOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className={
                                            (
                                                props.type === "warn" ?
                                                    "bg-red-100" :
                                                    props.type === "success" ?
                                                        "bg-green-100" :
                                                        props.type === "info" ?
                                                            "bg-blue-100" :
                                                            "bg-red-100"
                                            ) +
                                            " mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                                        }>
                                            {
                                                props.type === "warn" ?
                                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> :
                                                    props.type === "success" ?
                                                        <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> :
                                                        props.type === "info" ?
                                                            <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" /> :
                                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            }
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {props.title}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {props.text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className={
                                            (
                                                props.type === "warn" ?
                                                    "bg-red-600 hover:bg-red-500 text-white " :
                                                    props.type === "success" ?
                                                        "bg-green-600 hover:bg-green-500 text-white " :
                                                        props.type === "info" ?
                                                            "bg-blue-600 hover:bg-blue-500 text-white " :
                                                            "bg-red-600 hover:bg-red-500 text-white "

                                            ) +
                                            "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:ml-3 sm:w-auto"
                                        }
                                        onClick={props.onConfirm}
                                    >
                                        {props.confirmButtonText}
                                    </button>

                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center text-gray-900  hover:text-gray-500 text rounded-md px-3 py-2 text-sm font-semibold  sm:ml-3 sm:w-auto"
                                        onClick={props.onCancel}
                                        ref={cancelButtonRef}
                                    >
                                        {props.cancelButtonText}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export function Alert( props ) {

    const submitButtonRef = useRef( null )

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={submitButtonRef} onClose={props.sOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className={
                                            (
                                                props.type === "warn" ?
                                                    "bg-red-100" :
                                                    props.type === "success" ?
                                                        "bg-green-100" :
                                                        props.type === "info" ?
                                                            "bg-blue-100" :
                                                            "bg-red-100"
                                            ) +
                                            " mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                                        }>
                                            {
                                                props.type === "warn" ?
                                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> :
                                                    props.type === "success" ?
                                                        <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> :
                                                        props.type === "info" ?
                                                            <InformationCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" /> :
                                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                            }
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {props.title}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {props.text}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className={
                                            (
                                                props.type === "warn" ?
                                                    "bg-red-600 hover:bg-red-500 text-white " :
                                                    props.type === "success" ?
                                                        "bg-green-600 hover:bg-green-500 text-white " :
                                                        props.type === "info" ?
                                                            "bg-blue-600 hover:bg-blue-500 text-white " :
                                                            "bg-red-600 hover:bg-red-500 text-white "

                                            ) +
                                            "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:ml-3 sm:w-auto"
                                        }
                                        onClick={props.onClick}
                                        ref={submitButtonRef}
                                    >
                                        {props.buttonText}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export async function Request(url = "", data = {}) {
    if(url) {
        try {
            const backend = config.backend;

            const rsp = await fetch(backend + url, {
                method:'POST',
                body: JSON.stringify(data)
            }).then(rsp => rsp.json())
                .then(data => data)
                .catch(err => console.error(err));
            if(rsp) {
                if(rsp.code === 200) {
                    if(rsp.body) {
                        return rsp.body;
                    } else {
                        return true;
                    }
                } else if(rsp.code === 500) {
                    console.error(rsp.error);
                }
            } else {
                console.error('RESPONSE ERROR')
            }
        } catch(err) {
            console.error(err);
        }
    } else {
        console.error("URL REQUIRED");
    }

    return false;
}

export function Loading(props) {
    return (
        <>
            {
                props.show ? (
                    <div className="flex justify-center h-24">
                        <svg svg="http://www.w3.org/2000/svg" version="1.0" width="32px" height="32px" viewBox="0 0 128 128">
                            <g>
                                <circle cx="16" cy="64" r="16" fill="#000000"/>
                                <circle cx="16" cy="64" r="14.344" fill="#000000" transform="rotate(45 64 64)"/>
                                <circle cx="16" cy="64" r="12.531" fill="#000000" transform="rotate(90 64 64)"/>
                                <circle cx="16" cy="64" r="10.75" fill="#000000" transform="rotate(135 64 64)"/>
                                <circle cx="16" cy="64" r="10.063" fill="#000000" transform="rotate(180 64 64)"/>
                                <circle cx="16" cy="64" r="8.063" fill="#000000" transform="rotate(225 64 64)"/>
                                <circle cx="16" cy="64" r="6.438" fill="#000000" transform="rotate(270 64 64)"/>
                                <circle cx="16" cy="64" r="5.375" fill="#000000" transform="rotate(315 64 64)"/>
                                <animateTransform attributeName="transform" type="rotate" values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64" calcMode="discrete" dur="500ms" repeatCount="indefinite"></animateTransform>
                            </g>
                        </svg>
                    </div>
                ) : (
                    props.children
                )
            }
        </>
    )
}

export function Text(props) {
    return (
        <p
            className={classNames(
                ( styles.text[ "@default" ] ),
                ( styles.text[ props.palette ?? "" ] ?? "" ),
                ( props.className ?? "" )
            )}
        >
            {props.children}
        </p>
    );
}

export function Input(props) {

    return (
        <div className={classNames(
            ( styles.input[ "@default" ]?.group ),
            ( styles.input[ props.palette ?? "" ]?.group ?? "" ),
            ( props.className?.group ?? "" )
        )}>
            <label htmlFor={props.name}  className={classNames(
                ( styles.input[ "@default" ]?.label ),
                ( styles.input[ props.palette ?? "" ]?.label ?? "" ),
                ( props.className?.label ?? "" )
            )}>
                {props.label}
            </label>
            <input
                type={props.type}
                name={props.name}
                min={props.min}
                max={props.max}
                step={props.step}
                id={props.id}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete}
                onInput={(e) => props.onInput(e.target.value)}
                disabled={props.disabled}
                value={props.value}
                required={props.required}
                pattern={props.pattern}
                className={classNames(
                    ( styles.input[ "@default" ]?.input ),
                    ( styles.input[ props.palette ?? "" ]?.input ?? "" ),
                    ( props.className?.input ?? "" ),
                    ( props.label ? "mt-2" : "" )
                )}
            />
        </div>
    );
    
}

export function Fieldset(props) {
    return (
        <>
            <fieldset className={props.className}>
                <legend className="block text-sm font-medium leading-6 text-gray-900">{props.legend}</legend>
                <div className={classNames(
                    "-space-y-px rounded-md bg-white shadow-sm",
                    props.legend ? "mt-2" : ""
                )}>
                    {props.children}
                </div>
            </fieldset>
        </>
    )
}

export function Select(props) {
    return (
        <div className={classNames(
            ( styles.input[ "@default" ].group ),
            ( styles.input[ props.palette ?? "" ]?.group ?? "" ),
            ( props.className?.group ?? "" )
        )}>
            <label htmlFor={props.name}  className={classNames(
                ( styles.input[ "@default" ].label ),
                ( styles.input[ props.palette ?? "" ]?.label ?? "" ),
                ( props.className?.label ?? "" )
            )}>
                {props.label}
            </label>
            <select
                name={props.name}
                onChange={(e) => props.onChange(e.target.value)}
                value={props.value}
                id={props.id}
                required={props.required}
                className={classNames(
                    ( styles.input[ "@default" ].input ),
                    ( styles.input[ props.palette ?? "" ]?.input ?? "" ),
                    ( props.className?.input ?? "" )
                )}
            >
                {
                    props.items.map((item) => {
                        return <option key={item.value} value={item.value}>{item.label}</option>
                    })
                }
            </select>
        </div>
    );
}